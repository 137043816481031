<template>
  <component
    :is="clickable ? BaseLink : 'div'"
    :to="targets[0]?.url"
    :target="targets[0]?.targetAttribute"
    class="min-h-10 w-full flex center py-2 c-$c"
    :class="{ 'hover:c-$ch underlined duration': clickable }"
    :style="{
      '--c': textColor,
      '--ch': hoverLinkColor || textColor,
    }"
  >
    <cms-rich-text
      :content="{ richText, type: 'VfCanvasRichText' }"
      :links-hover-color="hoverLinkColor || textColor"
      class="line-clamp-2 w-full text-center container"
    />
  </component>
</template>

<script lang="ts" setup>
import { BaseLink } from '#components'
import type { Message, PromoBarColors } from '#types/components/cms/promo-bar'

const { content } = defineProps<{
  content: Message
}>()

const { clickable, hoverLinkColor, richText, targets = [], textColor, background, sectionId = '', id } = content
const isLowContrast = hasHighBrightness(textColor?.substring(1))

const colors = useState<Record<string, PromoBarColors>>('promoBarColors', () => ({}))
colors.value[sectionId || id] = { background, textColor, hoverLinkColor, isLowContrast }
</script>
